import { createUnifiedTheme, genPageTheme, palettes } from '@backstage/theme';

const blue = '#007ac9';
const black = '#0F0F0F';
const red = '#EA4335';
const white = '#ffffff';
const lightGray = '#F3F3F3';
const gray = '#DDDDDD';

export const intiveTheme = createUnifiedTheme({
  palette: {
    ...palettes.light,
    primary: {
      main: blue,
    },
    secondary: {
      main: black,
    },
    error: {
      main: red,
    },
    warning: {
      main: red,
    },
    info: {
      main: blue,
    },
    success: {
      main: '#35abe2',
    },
    background: {
      default: lightGray,
      paper: white, // Light grey
    },
    banner: {
      info: '#35abe2', // light blue banner
      error: red,
      text: '#343b58',
      link: '#565a6e',
    },
    errorBackground: red,
    warningBackground: red,
    infoBackground: gray,
    navigation: {
      submenu: {
        background: blue
      },
      background: black,
      indicator: red,
      selectedColor: blue,
      color: white,
      navItem: {
        hoverBackground: gray,
      },
    },
  },
  pageTheme: {
    home: genPageTheme({ colors: [black], shape: 'none' }),
    documentation: genPageTheme({
      colors: [black],
      shape: 'none',
    }),
    project: genPageTheme({
      colors: [black],
      shape: 'none',
    }),
    tool: genPageTheme({
      colors: [black],
      shape: 'none',
    }),
    library: genPageTheme({
      colors: [black],
      shape: 'none',
    }),
    technique: genPageTheme({ colors: [black], shape: 'none' }),
    other: genPageTheme({ colors: [black], shape: 'none' }),
    apis: genPageTheme({ colors: [black], shape: 'none' }),
  },
  fontFamily: 'Arial, sans-serif',
  components: {
    BackstageInfoCard: {
      styleOverrides: {},
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecorationLine: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Remove uppercase text
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#35abe2', // light blue on hover
          },
          color: '#FFFFFF',
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: '#35abe2', // light blue on hover
          },
          color: '#FFFFFF',
        },
      },
    },
  },
});
