import React from 'react';
import { Route } from 'react-router-dom';
import { RbacPage } from '@backstage-community/plugin-rbac';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis, keycloakOIDCAuthApiRef } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { UnifiedThemeProvider } from '@backstage/theme';
import { intiveTheme } from './themes/intive';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import {
  KeycloakGroupsListPage,
  KeycloakGroupsCreatePage,
  KeycloakGroupDetailPage,
} from '@internal/backstage-plugin-keycloak-groups';
import { WheelOfNamesPage } from '@internal/backstage-plugin-wheel-of-names';
import { NotificationsPage } from '@backstage/plugin-notifications';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { ToolsPage } from './components/tools/ToolsPage';
import { AnnouncementsPage } from '@backstage-community/plugin-announcements';

const app = createApp({
  apis,
  themes: [
    {
      id: 'intive-theme-light',
      title: 'intive theme light',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={intiveTheme} children={children} />
      ),
    },
  ],
  components: {
    SignInPage: props => {
      const configApi = useApi(configApiRef);
      if (configApi.getString('auth.environment') === 'local') {
        return <SignInPage {...props} auto providers={['guest']} />;
      }
      return (
        <SignInPage
          {...props}
          provider={{
            id: 'keycloak-oidc',
            title: 'Keycloak',
            message: 'Sign in using Keycloak',
            apiRef: keycloakOIDCAuthApiRef,
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <Mermaid
          config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
        />
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/rbac" element={<RbacPage />} />

    <Route
      path="/devtools"
      element={
        <RequirePermission permission={devToolsAdministerPermission}>
          <DevToolsPage />
        </RequirePermission>
      }
    >
      {customDevToolsPage}
    </Route>
    <Route path="/keycloak-groups" element={<KeycloakGroupsListPage />} />
    <Route
      path="/keycloak-groups/create"
      element={<KeycloakGroupsCreatePage />}
    />
    <Route
      path="/keycloak-groups/:groupId"
      element={<KeycloakGroupDetailPage />}
    />
    <Route path="/wheel-of-names" element={<WheelOfNamesPage />} />
    <Route path="/notifications" element={<NotificationsPage />} />
    <Route path="/tools" element={<ToolsPage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
