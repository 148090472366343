import { DevToolsLayout } from '@backstage/plugin-devtools';
import React from 'react';
import { WheelOfNamesPage } from '@internal/backstage-plugin-wheel-of-names';

export const ToolsPage = () => {
  return (
    <DevToolsLayout title="Tools">
      <DevToolsLayout.Route path="wheel-of-names" title="Wheel of Names">
        <WheelOfNamesPage />
      </DevToolsLayout.Route>
    </DevToolsLayout>
  );
};

export const toolsPage = <ToolsPage />;
