import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
    fill: '#FFFFFF',
  },
  path: {
    fill: '#FFFFFF',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 191.29 954.42"
    >
          <rect
            className={classes.svg}
            x="2.03"
            y="0.5"
            width="188.77"
            height="188.78"
          />
          <rect
            className={classes.path}
            x="2.03"
            y="0.5"
            width="188.77"
            height="188.78"
          />
          <path
            className={classes.svg}
            d="M1,270.85H189.52V778.69c0,95-67.15,175.23-189,175.23V854.26c50-7,85.32-28.51,85.32-73.66V726H1Z"
          />
          <path
            className={classes.path}
            d="M1,270.85H189.52V778.69c0,95-67.15,175.23-189,175.23V854.26c50-7,85.32-28.51,85.32-73.66V726H1Z"
          />
    </svg>
  );
};

export default LogoIcon;
